<template>
  <div>
    <div v-if="!cart && !widgetItem" class="col-md-12">
      <div class="d-flex align-items-center mt-2 mb-3" v-if="selectedColors.length">
        <div class="font-weight-bold">Quantity: {{ totalQuantity }}</div>
        <div class="ml-2">|</div>
        <div class="d-flex align-items-center ml-2">
          <span class="mr-2">Colors:</span>
          <span class="quantity-text-value">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center" v-b-tooltip.hover :title="color.name" v-bind:key="color.number" v-for="color in selectedColors">
                <div :style="`background: #${color.hex};`" class="color-square"></div>
              </div>
            </div>
          </span>
        </div>
      </div>
      <div class="d-flex">
        <button class="btn btn-primary" @click="showColorQuantityPicker()" :disabled="updatingCart">
          <div v-if="updatingCart" class="spinner-border spinner-border-sm mr-2"></div>
          Choose Quantity and Assign Colors
        </button>
        <button class="btn btn-outline-primary ml-3" v-if="selectedColors.length && showRemoveItem" :disabled="updatingCart" @click="addItemToCart(true)">
          <div v-if="updatingCart" class="spinner-border spinner-border-sm"></div>
          <svg v-else width="12" height="14" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor" fill-rule="nonzero"><path d="M10.846 1.684H8.308v-.42C8.308.565 7.688 0 6.923 0H5.077c-.765 0-1.385.566-1.385 1.263v.421H1.154C.517 1.684 0 2.156 0 2.737v.842C0 3.81.207 4 .462 4h11.076c.255 0 .462-.188.462-.421v-.842c0-.582-.517-1.053-1.154-1.053zm-6.23-.42c0-.233.207-.422.46-.422h1.847c.255 0 .462.19.462.421v.421h-2.77v-.42zM1.137 4c-.078 0-.14.08-.137.176l.362 8.29c.033.86.61 1.534 1.312 1.534h6.652c.703 0 1.28-.673 1.312-1.533L11 4.176C11.004 4.08 10.94 4 10.863 4H1.137zm6.615 1.341c0-.296.196-.536.438-.536s.438.24.438.536v5.976c0 .297-.196.537-.438.537s-.438-.24-.438-.537V5.341zm-2.19 0c0-.296.196-.536.438-.536s.438.24.438.536v5.976c0 .297-.196.537-.438.537s-.438-.24-.438-.537V5.341zm-2.19 0c0-.296.196-.536.438-.536s.438.24.438.536v5.976c0 .297-.196.537-.438.537s-.438-.24-.438-.537V5.341z"/></g></svg>
        </button>
      </div>
    </div>
    <template v-else-if="cart">
      <template>
        <div v-if="selectedColors.length" class="d-md-flex align-items-center ml-2">
          <div v-if="!compactView" class="font-weight-bold">Quantity: {{ totalQuantity }}</div>
          <div v-if="!compactView" class="ml-2 d-none d-md-block">|</div>
          <div class="d-flex align-items-center ml-2">
            <span class="mr-2">Colors:</span>
            <span class="quantity-text-value">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center" v-b-tooltip.hover :title="color.name" v-bind:key="color.number" v-for="color in selectedColors">
                  <div :style="`background: #${color.hex};`" class="color-square">
                    {{ color.quantity }}
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
        <div v-if="!compactView" class="d-flex justify-content-end mt-2">
          <button :disabled="updatingCart" class="ml-2 btn-action" @click="showColorQuantityPicker()">
            <div v-if="updatingCart" class="spinner-border spinner-border-sm"></div>
            <i v-else class="fa fa-edit"></i>
          </button>
          <button :disabled="updatingCart" class="ml-2 btn-action" @click="addItemToCart(true)">
            <div v-if="updatingCart" class="spinner-border spinner-border-sm"></div>
            <svg v-else class="fill-primary" width="12" height="14" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fill-rule="nonzero"><path d="M10.846 1.684H8.308v-.42C8.308.565 7.688 0 6.923 0H5.077c-.765 0-1.385.566-1.385 1.263v.421H1.154C.517 1.684 0 2.156 0 2.737v.842C0 3.81.207 4 .462 4h11.076c.255 0 .462-.188.462-.421v-.842c0-.582-.517-1.053-1.154-1.053zm-6.23-.42c0-.233.207-.422.46-.422h1.847c.255 0 .462.19.462.421v.421h-2.77v-.42zM1.137 4c-.078 0-.14.08-.137.176l.362 8.29c.033.86.61 1.534 1.312 1.534h6.652c.703 0 1.28-.673 1.312-1.533L11 4.176C11.004 4.08 10.94 4 10.863 4H1.137zm6.615 1.341c0-.296.196-.536.438-.536s.438.24.438.536v5.976c0 .297-.196.537-.438.537s-.438-.24-.438-.537V5.341zm-2.19 0c0-.296.196-.536.438-.536s.438.24.438.536v5.976c0 .297-.196.537-.438.537s-.438-.24-.438-.537V5.341zm-2.19 0c0-.296.196-.536.438-.536s.438.24.438.536v5.976c0 .297-.196.537-.438.537s-.438-.24-.438-.537V5.341z"/></g></svg>
          </button>
        </div>
      </template>
    </template>

    <ColorQuantityModal
      @addColorAndQuantity="addColorAndQuantity"
      v-if="product"
      ref="colorQuantity"
      :qty="quantity"
      :product="product"
      :special="special"
      :limit="limit"
      :variants="selectedColors"
      :showRemoveItem="quantity > 0"
    />
  </div>
</template>

<script>
import ColorQuantityModal from '@/pages/paints/color-quantity-modal';
import {addToCart, removeFromCart} from "@/plugins/analytics";
import CartApiService from '@/api-services/cart.service';

export default {
  name: "add-to-cart-widget",
  components: {
    ColorQuantityModal
  },
  props: {
    product: {
      type: Object
    },
    quantity: {
      type: Number,
      default: 1
    },
    special: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: -1
    },
    variants: {
      type: Array,
      default: null
    },
    cart: {
      type: Boolean,
      default: false
    },
    widgetItem: {
      type: Boolean,
      default: false
    },
    showRemoveItem: {
      type: Boolean,
      default: false
    },
    compactView: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.selectedColors = this.variants ? this.variants : this.getVariantIfAlreadyInCart();
  },
  data() {
    return {
      selectedColors: [],
      updatingCart: false
    };
  },
  computed: {
    isAdmin() {
      return this.$store.state.activeUser && this.$store.state.activeUser.is_admin;
    },
    totalQuantity() {
      return this.selectedColors.reduce((a, b) => a + (b.quantity || 0), 0);
    }
  },
  methods: {
    showColorQuantityPicker() {
      this.$refs.colorQuantity.showModal();
    },
    getVariantIfAlreadyInCart() {
      const cartItems = this.$store.state.cart.parcels;
      if (cartItems && cartItems.length) {
        let pickupParcel = cartItems.find(item => {
          return item.type === 'pickup';
        });
        if ( !pickupParcel ) {
          pickupParcel = {items: []};
        }
        const specialParcels = cartItems.filter(item => {
          return item.type === 'special';
        });
        let specialItems = [];
        if (specialParcels && specialParcels.length) {
          specialParcels.forEach(parcel => {
            if (parcel.items && parcel.items.length) {
              specialItems = [...specialItems, ...parcel.items];
            }
          });
        }
        const items = [...specialItems, ...pickupParcel.items];

        // sum the quantity in all matching items, items.find() just did the first one.
        return items.map(item => {
          if (item.store_product_id == this.product.id) {
            return item.variants;
          }
        }).flat().filter(Boolean);
      }
      else return [];
    },
    addColorAndQuantity(selectedColors) {
      if(this.isAdmin) {
        this.$swal("You can't add products as Admin", '', 'error');
        return;
      }
      if(this.selectedColors.length && !selectedColors.length) {
        this.addItemToCart(true);
        return;
      }
      this.selectedColors = selectedColors;
      if(this.selectedColors.length == 0 && !this.cart) {
        return;
      }
      this.addItemToCart();
    },
    addItemToCart(remove = false) {
      if(this.isAdmin) {
        this.$swal("You can't add products as Admin", '', 'error');
        return;
      }

      if (!remove && !this.cart) {
        this.$swal({
          customClass: {
            container: 'add-to-cart-toast-container',
            popup: 'add-to-cart-toast',
          },
          toast: true,
          position: 'top',
          title: this.product.title,
          html: `<img src="${this.product.image_url || '/images/default-img.svg'}"><br>Product has been added to cart <a href="/cart" class="btn btn-outline-primary mt-3 mb-2">Go to checkout</a>`,
          showConfirmButton: false,
          timer: 5000
        });
      }

      // add fields that are expected by the backend and newer code
      const variants = this.selectedColors.map(c => {
        return {
          type: 'color',
          label: 'Color',
          code: c.number,
          value: c.number,
          ...c
        };
      });

      if(this.totalQuantity == 0 || remove) {
        this.$store.state.addingToCart = true;
        this.updatingCart = true;
        CartApiService.removeItem(this.product.store_product_id  || this.product.id, Number(this.special), variants).then(async () => {
            await this.$store.dispatch('fetchCartItemsDetails');
            removeFromCart(this.product, this.totalQuantity);
            this.$store.state.addingToCart = false;
            this.updatingCart = false;
            this.selectedColors = [];
            console.log(this.product);
            // this.quantity = 0;
          });
      } else {
        this.$store.state.addingToCart = true;
        this.updatingCart = true;
        CartApiService.addItem(this.product, this.product.store_product_id || this.product.id,
          this.totalQuantity, Number(this.special), variants).then(async res => {
            if(res.status === 200) {
              await this.$store.dispatch('fetchCartItemsDetails');
              addToCart(this.product, this.quantity);
            } else {
              this.$swal(res.data.message, '', 'error');
            }
            this.$store.state.addingToCart = false;
            this.updatingCart = false;
            this.$emit('updateQty', this.totalQuantity);
            // this.quantity = this.totalQuantity;
          })
          .catch(err => {
            this.$swal('Error', Object.keys(err.response.data.errors).map(key => err.response.data.errors[key]).join('<br />'), 'error');
            this.$store.state.addingToCart = false;
            this.updatingCart = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.color-quantity {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */


  /* Blue Gray / 500 */

  color: #64748B;
  background: none;
  width: 100%;
  border: 1px dashed #64748B;
  border-radius: 5px;
  padding: 15px;
}
.quantity-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  /* Blue Gray / 500 */

  color: #64748B;
}
.quantity-text-value {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: right;

  /* Blue Gray / 800 */

  color: #1E293B;
}
.color-square {
  width: 22px;
  height: 22px;
  margin-right: 4px;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px #aaa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
}
@media (max-width: 768px) {
  .color-square {
    width: 12px;
    height: 12px;
  }
}
.color-separator {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-align: right;

  /* Blue Gray / 200 */
  color: #E2E8F0;
  margin-left: 2px;
  margin-right: 2px;
}
.btn-action {
  border: none;
  border-radius: 4px;
  padding: 3px 9px;
  color: var(--primary);
  background: #f1f0f0;
}
</style>
