import Axios from 'axios';
import EZAxios from '@/ezaxios';

export default {
  getTaxonomies(parentId = null) {
    return EZAxios.get(`paints/taxonomies${parentId ? `?parent_id=${parentId}` : ''}`);
  },
  getTaxonomyProducts(filters) {
    return EZAxios.get(`paints/taxonomies-products?${new URLSearchParams(filters)}`);
  },
  async getColors(codes = []) {
    let request = Axios.CancelToken.source();
    request.cancel('it works!');
    request = Axios.CancelToken.source();
    return EZAxios.get(`paints/colorsV2?codes=${codes.join(',')}`, { cancelToken: request.token }).catch(() => {});
  },
  getColorCategories() {
    return EZAxios.get(`paints/categories`);
  }
};
