<template>
  <b-modal body-class="d-flex flex-column p-0 pb-4 pb-md-0" title="Assign colors to items" @close="hideModal" size="xxl" ref="colorQuantity" hide-footer>
    <div class="w-100 d-flex flex-grow-1 align-items-center justify-content-center" v-if="loading">
      <div class="spinner-border" />
    </div>
    <div v-else>
      <div class="d-flex align-items-center mx-4 my-3 flex-wrap">
        <div v-for="(c, i) in colorFamilies" class="color-item mb-2 d-flex align-items-center justify-content-center flex-grow-1 position-relative cursor-pointer" :key="c.name" :style="`background: ${customHex(c.name)}`" @click="selectFamily(c.name)" :class="{ 'active': selectedFamily == c.name, 'pointer-events-none': loadingColors }">
          <div v-if="i == 0" class="position-absolute">
            White
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-lg-row flex-grow-1">
        <div class="sidebar p-4 border d-none d-lg-block" v-if="colorCollections">
          <div class="custom-control custom-checkbox mb-2" v-for="collection in colorCollections" :key="collection.name">
            <input :id="collection.name" type="checkbox" v-model="codes" :value="collection.code" class="custom-control-input">
            <label class="custom-control-label" :for="collection.name">
              <div v-html="collection.name"></div>
              <div v-if="collection.code == 'CSP'" class="small text-warning">
                Aura products only
              </div>
            </label>
          </div>
        </div>
        <div class="flex-grow-1 px-4 pr-md-0 pl-lg-2">
          <div class="row">
            <div class="col-md-8">
              <input v-model="searchTerm" class="form-control mb-2" placeholder="search by name or number" type="text" />
              <div class="clr-plate-box d-flex flex-wrap flex-column overflow-auto align-content-start position-relative" v-if="colorList" ref="colorGrid">
                <div class="h-100 w-100 d-flex flex-grow-1 align-items-center justify-content-center position-absolute spinner-wrapper" v-if="loadingColors">
                  <div class="spinner-border" />
                </div>
                <div class="w-100 mt-3 text-center" v-else-if="!allColors.length">
                  <h5>No colors to show! Try again</h5>
                </div>
                <div v-else v-for="c in allColors" class="color-div d-flex cursor-pointer align-items-end pl-2 pb-2" :key="c.number" :style="`background: #${c.hex}`" @click="selectColor(c)" :class="{ 'selected' : selectedColor && c.number == selectedColor.number }">
                  <div class="color-name" :style="`color: ${textColor(c.hex)}`">
                    <div class="name" v-html="searchTerm ? c.name.replace(new RegExp(searchTerm, 'i'), m => `<mark>${m}</mark>`) : c.name"></div>
                    <div class="font-weight-bold" v-html="searchTerm ? c.number.replace(new RegExp(searchTerm, 'i'), m => `<mark>${m}</mark>`) : c.number"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mt-3 mt-md-0">
              <div class="pr-md-4">
                <div v-if="selectedColor" class="mb-3">
                  <label class="text-muted h5">Details</label>
                  <div class="d-flex align-items-center mb-2">
                    <div class="mr-2" :style="`background: #${selectedColor.hex}`" style="width: 30px; height: 30px"></div>
                    <h6 class="font-weight-bold mb-0">{{ selectedColor.number }} - {{ selectedColor.name }}</h6>
                  </div>
                  <p class="text-muted">A fresh tan that feels beachy and uptown. It can go casual or elegant.</p>
                  
                  <div class="d-flex change-quantity">
                    <button type="button" class="btn btn-primary" :disabled="quantity <= 1" @click="() => quantity--">
                      <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 1H1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </button>
                    <input v-model="quantity" :disabled="stopAddingQuantity" type="number" min="1" :max="limit - finalQuantity" maxlength="4" inputmode="numeric" pattern="[0-9]*" oninput="validity.valid||(value='');" class="form-control mx-2 text-center font-weight-bold" />
                    <button type="button" class="btn btn-primary" :disabled="finalQuantity + quantity == limit" @click="() => quantity++">
                      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M4 0C4.21217 0 4.41566 0.0842856 4.56569 0.234315C4.71571 0.384344 4.8 0.587827 4.8 0.8V3.2H7.2C7.41217 3.2 7.61566 3.28429 7.76569 3.43431C7.91571 3.58434 8 3.78783 8 4C8 4.21217 7.91571 4.41566 7.76569 4.56569C7.61566 4.71571 7.41217 4.8 7.2 4.8H4.8V7.2C4.8 7.41217 4.71571 7.61566 4.56569 7.76569C4.41566 7.91571 4.21217 8 4 8C3.78783 8 3.58434 7.91571 3.43431 7.76569C3.28429 7.61566 3.2 7.41217 3.2 7.2V4.8H0.8C0.587827 4.8 0.384344 4.71571 0.234315 4.56569C0.0842856 4.41566 0 4.21217 0 4C0 3.78783 0.0842856 3.58434 0.234315 3.43431C0.384344 3.28429 0.587827 3.2 0.8 3.2H3.2V0.8C3.2 0.587827 3.28429 0.384344 3.43431 0.234315C3.58434 0.0842856 3.78783 0 4 0Z" fill="currentColor"/></svg>
                    </button>
                  </div>
                  <button class="btn btn-success w-100 mt-4" @click="addColor" :disabled="limit <= 0 || quantity == 0">
                    Add this color To Cart
                  </button>
                  <div class="small text-danger font-weight-bold mt-1 text-right">
                    Limit: {{ limit }}
                  </div>
                </div>
                <div class="pt-3 mb-4 border-top" v-if="selectedColors.length">
                  <label>Selected Colors ({{ selectedColors.length }})</label>
                  <div class="d-flex align-items-center justify-content-between selected-color mb-2" :key="c.number" v-for="c in selectedColors">
                    <div class="d-flex align-items-center text-truncate">
                      <div class="mr-1 rounded-sm color" :style="`background: #${c.hex}`"></div>
                      <div class="text-truncate small font-weight-bold" data-toggle="tooltip" data-placement="top" :title="c.name">{{ c.number }} - {{ c.name }}</div>
                    </div>
                    <div class="d-flex align-items-center">
                      <div class="change-quantity sm d-flex justify-content-center">
                        <button type="button" class="btn action-bt btn-primary clr-detailed-btn-sm" @click="editQuantity(c, -1)">-</button>
                        <input v-model="c.quantity" :disabled="stopAddingQuantity" type="number" min="0" max="9999" maxlength="4" inputmode="numeric" pattern="[0-9]*" class="clr-detailed-input form-control bg-white mx-1 text-center font-weight-bold" @keyup="validateNumber(index)" @blur="validateNumber(index)"/>
                        <button type="button" class="btn action-bt btn-primary clr-detailed-btn-sm" :disabled="finalQuantity >= limit" @click="editQuantity(c, 1)">+</button>
                      </div>
                      <div class="d-flex">
                        <!--<button v-if="false" class="clr-detailed-number-edit btn-edit" @click="setSelectedColor(c, c.quantity)"></button>-->
                        <button class="bg-transparent border-0 p-0 ml-1" @click="removeColor(c)">
                          <svg width="15" height="18" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.8001 0.900391C7.5773 0.90051 7.35894 0.96265 7.16947 1.07985C6.97999 1.19706 6.82689 1.36469 6.7273 1.56399L5.8585 3.30039H1.8001C1.48184 3.30039 1.17661 3.42682 0.951569 3.65186C0.726526 3.87691 0.600098 4.18213 0.600098 4.50039C0.600098 4.81865 0.726526 5.12388 0.951569 5.34892C1.17661 5.57396 1.48184 5.70039 1.8001 5.70039V17.7004C1.8001 18.3369 2.05295 18.9474 2.50304 19.3974C2.95313 19.8475 3.56358 20.1004 4.2001 20.1004H13.8001C14.4366 20.1004 15.0471 19.8475 15.4972 19.3974C15.9472 18.9474 16.2001 18.3369 16.2001 17.7004V5.70039C16.5184 5.70039 16.8236 5.57396 17.0486 5.34892C17.2737 5.12388 17.4001 4.81865 17.4001 4.50039C17.4001 4.18213 17.2737 3.87691 17.0486 3.65186C16.8236 3.42682 16.5184 3.30039 16.2001 3.30039H12.1417L11.2729 1.56399C11.1733 1.36469 11.0202 1.19706 10.8307 1.07985C10.6413 0.96265 10.4229 0.90051 10.2001 0.900391H7.8001ZM5.4001 8.10039C5.4001 7.78213 5.52653 7.47691 5.75157 7.25186C5.97661 7.02682 6.28184 6.90039 6.6001 6.90039C6.91836 6.90039 7.22358 7.02682 7.44863 7.25186C7.67367 7.47691 7.8001 7.78213 7.8001 8.10039V15.3004C7.8001 15.6187 7.67367 15.9239 7.44863 16.1489C7.22358 16.374 6.91836 16.5004 6.6001 16.5004C6.28184 16.5004 5.97661 16.374 5.75157 16.1489C5.52653 15.9239 5.4001 15.6187 5.4001 15.3004V8.10039ZM11.4001 6.90039C11.0818 6.90039 10.7766 7.02682 10.5516 7.25186C10.3265 7.47691 10.2001 7.78213 10.2001 8.10039V15.3004C10.2001 15.6187 10.3265 15.9239 10.5516 16.1489C10.7766 16.374 11.0818 16.5004 11.4001 16.5004C11.7184 16.5004 12.0236 16.374 12.2486 16.1489C12.4737 15.9239 12.6001 15.6187 12.6001 15.3004V8.10039C12.6001 7.78213 12.4737 7.47691 12.2486 7.25186C12.0236 7.02682 11.7184 6.90039 11.4001 6.90039Z" fill="#C92E2E"/></svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="btn btn-primary mt-2 w-100" :disabled="finalQuantity == 0" @click="addColorAndQuantity" v-if="selectedColors.length">
                  <span class="h6 m-0">Done</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import PaintsService from '@/api-services/paints.service';

export default {
  name: "color-quantity-modal",
  props: {
    product: {
      type: Object,
      default: null
    },
    limit: {
      type: Number,
      default: 1
    },
    special: {
      type: Boolean,
      default: false
    },
    variants: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      loadingColors: false,
      selectedColors: [],
      selectedColor: null,
      searchTerm: '',
      selectedFamily: null,
      codes: [],
      quantity: 1
    };
  },
  computed: {
    finalQuantity() {
      return this.selectedColors.map(e => e.quantity).reduce((a, b) => a + b, 0);
    },
    colorFamilies() {
      let arr = this.colorCategories ? this.colorCategories.find(e => e.category == 'family').palettes.filter(e => Object.keys(this.colorList).includes(e.name)) : null;
      if(!arr) return null;
      return [{ code: "HAC", name: "Hack", totalColors: 2 }, ...arr];
    },
    colorCollections() {
      return this.colorCategories ? this.colorCategories.find(e => e.category == 'collection').palettes : null;
    },
    colorCategories() {
      return this.$store.state.colorCategories;
    },
    colorList() {
      return this.$store.state.colorFacets || [];
    },
    allColors() {
      if(this.colorList && this.selectedFamily == 'Hack') {
        return Object.entries(this.colorList).map(e => e[1]).flat().filter(e => e.name == 'White');
      }
      return this.colorList ? 
        Object.entries(this.colorList).map(e => e[1]).flat()
        .filter(e => this.selectedFamily ? e.family == this.selectedFamily : e)
        .filter(e => String(`${e.number} ${e.name}`).toLowerCase().includes(this.searchTerm.toLowerCase())) : [];
    },
    stopAddingQuantity() {
      return this.product.num_inventory == 0 && (!this.isSpecialOrderAllowed);
    },
    limitReached() {
      return this.finalQuantity + (this.quantity == 0 ? this.quantity : this.quantity - 1) == this.limit;
    }
  },
  methods: {
    async showModal() {
      this.loading = true;
      this.$refs.colorQuantity.show();
      this.resetFilters();
      this.selectedColors = [];
      if(this.product.is_paint) {
        if(this.$store.state.cart && this.$store.state.cart.parcels) {
          this.$store.state.cart.parcels.forEach(e => {
            e.items.forEach(i => {
              if(i.sku == this.product.sku)
                this.selectedColors = i.variants || [];
            });
          });
        }
        if(!this.$store.state.colorCategories) {
          let r = await PaintsService.getColorCategories();
          if(r.data.status === 'success') {
            this.$store.commit('setColorCategories', r.data.categories);
          }
        }
      }
      this.checkQuantity();
      this.selectFirstColor();
    },
    checkQuantity() {
      this.quantity = this.limitReached ? 0 : 1;
    },
    hideModal() {

    },
    selectFirstColor() {
      this.selectedColor = this.allColors[0];
    },
    selectFamily(family) {
      this.selectedFamily = this.selectedFamily == family ? null : family;
      this.$refs.colorGrid.scrollTo({ left: 0 });
    },
    customHex(color) {
      return color == 'Hack' ? '#f9f9f9' : color == 'Pink' ? '#F27A92' : color == 'Orange' ? '#F28E16' : color == 'Red' ? '#E41934' : color == 'Brown' ? '#816556' : color == 'Neutral' ? '#C1B28B' : color == 'Gray' ? '#8F9293' : color == 'White' ? '#E1E1E1' : color == 'Yellow' ? '#FFEC00' : color == 'Purple' ? '#745184' : color == 'Blue' ? '#4376A3' : color == 'Black' ? '#000000' : color == 'Green' ? '#3F993F' : color == 'N/A' ? 'linear-gradient(to right, #fdbb2d, #b21f1f, #1a2a6c)' : '';
    },
    textColor(c) {
      var rgb = parseInt(c, 16);   // convert rrggbb to decimal
      var r = (rgb >> 16) & 0xff;  // extract red
      var g = (rgb >>  8) & 0xff;  // extract green
      var b = (rgb >>  0) & 0xff;  // extract blue
      var l = 0.2126 * r + 0.7152 * g + 0.0722 * b;
      let a = l < 150 ? 130 : -130;
      return '#' + c.replace(/^#/, '').replace(/../g, c => ('0' + Math.min(255, Math.max(0, parseInt(c, 16) + a)).toString(16)).substr(-2));
    },
    resetFilters() {
      this.selectedFamily = null;
      this.codes = [];
    },
    async getColors() {
      this.loadingColors = true;
      return await PaintsService.getColors(this.codes).then(r => {
        if(r && r.data.status === 'success') {
          this.$store.commit('setColorFacets', r.data.colors);
          if(!Object.keys(this.colorList).includes(this.selectedFamily))
            this.selectedFamily = null;
          this.loadingColors = false;
          this.loading = false;
          this.selectFirstColor();
        }
      });
    },
    addColor() {
      if(this.quantity + this.selectedColors.reduce((a, b) => a + b.quantity, 0) > this.limit) {
        return;
      }
      let colorObj = this.selectedColors.find(e => e.number == this.selectedColor.number);
      if(colorObj) {
        colorObj.quantity += this.quantity;
      } else {
        this.selectedColors.push({ ...this.selectedColor, quantity: this.quantity });
      }
      if(this.quantity >= this.finalQuantity)
        this.quantity = 0;
      this.checkQuantity();
    },
    removeColor(c) {
      this.selectedColors = this.selectedColors.filter(e => e != this.selectedColors.find(e => e.number == c.number));
      this.quantity = this.quantity == 0 ? 1 : this.quantity;
    },
    selectColor(c) {
      this.checkQuantity();
      this.selectedColor = c;
    },
    editQuantity(c, n) {
      let o = this.selectedColors.find(e => e.number == c.number);
      o.quantity += n;
      if(o.quantity == 0)
        this.removeColor(c);
      console.log(this.limitReached);
        this.quantity = 0;
    },
    addColorAndQuantity() {
      this.$emit('addColorAndQuantity', this.selectedColors);
      this.$refs.colorQuantity.hide();
      this.resetFilters();
    },
  },
  watch: {
    codes() {
      this.getColors();
    }
  }
};
</script>

<style lang="scss" scoped>

  :deep(.modal-dialog) {
    .modal-content {
      border-radius: 24px !important;
      min-height: 660px !important;
      overflow: hidden !important;
    }
  }
  :deep(mark) {
    padding: 0;
  }
  ::-webkit-scrollbar {
    height: 6px;
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    height: 6px;
    background: #E2E8F0;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb {
    height: 6px;
    background: var(--primary);
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
  }
  .spinner-wrapper {
    background: rgba(255,255,255,.7);
  }
  .color-item {
    height: 70px;
    transition: all .1s;
    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid #ccc;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &:hover {
      transform: scale(1.1);
      z-index: 100;
    }
    &.active {
      transform: scale(1.1);
      z-index: 50;
    }
  }
  .color-name {
    font-size: 12px;
    line-height: 14px;
  }
  .change-quantity {
    input, button {
      height: 40px;
    }
    button {
      padding: 0;
      min-width: 40px;
      width: 40px;
    }
  }
  .selected-color {
    .color {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
    .change-quantity {
      input {
        height: 20px;
        width: 30px;
        min-width: 30px;
        padding: 0 !important;
      }
      button {
        height: 20px;
        max-width: 20px;
        width: 20px;
        min-width: 20px;
      }
    }
  }
  .sidebar {
    background: #F8FAFC;
  }
  .clr-plate-box {
    height: 420px;
    .color-div {
      width: 107px;
      height: 68px;
      &.selected, &:hover {
        outline: 2px solid white;
        z-index: 1;
        box-shadow: 0 0 15px rgba(0,0,0,.8);
      }
    }
  }

  @media (max-width:991px) {
    :deep(.modal-dialog) {
      top: 0;
      transform: none !important;
      left: 0;
      .modal-content {
        width: 100%;
        margin: 0;
        min-height: calc(var(--vh, 1vh) * 100);
        .modal-body {
          padding: 1rem;
        }
      }
    }
    .color-item {
      height: 53px;
      min-width: 57px;
      max-width: 57px;
      &:hover, &.active {
        transform: none;
      }
      &.active {
        &::before {
          content: url("data:image/svg+xml,%3Csvg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 9.5L5.58579 13.0858C6.36684 13.8668 7.63317 13.8668 8.41421 13.0858L19.5 2' stroke='white' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          width: 17.5px;
          height: 12.5px;
          margin: -1px 0 0 -5px;
        }
      }
    }
    .color-name {
      .name {
        line-height: 12px;
        font-size: 9px;
      }
    }
  }
</style>